import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import { buildBreadcrumbsItem, collectionUrl } from '../helpers'
import styles from '../scss/page.scss'

class aboutSubscriptionPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'About Auto-Refill | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'The Honest Paws Auto-Refill program is the best way to ensure your pet gets their CBD delivery on time.'
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url'  />
      ]
    }

    this.breadcrumbs = [
      buildBreadcrumbsItem('About Auto-Refill', this.props.location.href)
    ]

    this.iconNoContract = this.props.data.iconNoContract.childImageSharp.fixed
    this.iconSaveMoney = this.props.data.iconSaveMoney.childImageSharp.fixed
    this.iconSelectProduct = this.props.data.iconSelectProduct.childImageSharp.fixed
    this.iconSetSchedule = this.props.data.iconSetSchedule.childImageSharp.fixed
    this.moneyGuarantee = this.props.data.moneyGuarantee.childImageSharp.fluid
  }

  render() {
    return (
      <Layout containerClassName={`page page-about-subscription`} location={this.props.location} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo }>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>
        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">About Auto-Refill</h1>
            </div>
          </div>
        </section>
        <section className="bg-white about-subscription">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 offset-md-2 text-center text-gray-dark">
                <h2>How It Works</h2>
                <p>The Honest Paws Auto-Refill program is the best way to ensure your pet gets their CBD delivery
                  on time. When you select Auto-Refill for your favorite Honest Paws product, you choose how often you get your
                  product <b>and</b> you save BIG with every delivery - <b>save 15%</b> on every single order. The Honest Paws Auto-Refill program is <b>simple, straight-forward, and will
                  truly make your life easier.</b></p>
                <a href="https://www.honestpaws.com/collections/" className="btn btn-primary my-5" target="_top">Shop Auto-Refill Products!</a>
              </div>
            </div>
          </div>
          <div className="image-subscription-section"></div>
          <div className="container mt-5">
            <div className="row">
              <div className="col-12 col-md-10 mx-auto">
                <p className="text-left">
                  CBD is incredibly effective at relieving discomfort, promoting relaxation, and combating seasonal
                  allergies & skin irritation. However, it is only effective when used consistently. <br/><br/>
                  With that said, life gets busy. Even for the most dedicated pet parent, it can be hard to remember to
                  take time out of your busy schedule to get online, go to <a href="https://www.honestpaws.com" target="_top">www.honestpaws.com</a>, make your selection, and
                  input your payment information. <br/><br/>
                  Luckily, when you sign up for the Auto-Refill program, you never have to worry about accidental
                  lapses in your pet’s CBD regimen. All you have to do is set it and forget it. <br/><br/>
                  With the Honest Paws Auto-Refill program, you choose exactly how often you want to receive your CBD product.
                  Whether it’s once a month, every other month, or every six months, there are no limitations on how you
                  customize your deliveries. <br/><br/>
                  Not to mention, with the Honest Paws Auto-Refill program, you can add additional products, remove products,
                  or skip a month. <br/><br/>
                  You will even receive exclusive offers especially created for you based on your pet’s needs. <br/><br/>
                  And if you decide you want to cancel your Auto-Refill program? Just let us know and we will take care of it
                  immediately. No 30 day cancellation notice. <b>No contracts. No hassle.</b> <br/><br/>
                  Signing up for the Honest Paws Auto-Refill program is easy. All you have to do is choose your product, select
                  the <b>Auto-Refill</b> option, and follow the prompts to customize your Auto-Refill program.
                </p>
                <hr className="mt-5"/>
                <div className="d-md-flex">
                  <div className="col-12 col-md-3 my-3 text-center about-subscription-details">
                    <div className="icon-image-container d-flex align-items-center mb-5">
                      <Img className="lazyload mx-auto" fixed={this.iconSelectProduct} alt="Icon select product" objectFit="contain"/>
                    </div>
                    <div className="text-primary">
                      Get Started
                    </div>
                    <p>
                      Select a CBD product of your choice and choose Auto-Refill before checkout.
                    </p>
                  </div>
                  <div className="col-12 col-md-3 my-3 text-center about-subscription-details">
                    <div className="icon-image-container d-flex align-items-center mb-5">
                      <Img className="lazyload mx-auto" fixed={this.iconSaveMoney} alt="Icon save money" objectFit="contain"/>
                    </div>
                    <div className="text-primary">
                      Save Instantly
                    </div>
                    <p>
                      Save major bucks with an Auto-Refill program. Save 15% on each delivery, depending on what product you choose.
                    </p>
                    </div>
                  <div className="col-12 col-md-3 my-3 text-center about-subscription-details">
                    <div className="icon-image-container d-flex align-items-center mb-5">
                      <Img className="lazyload mx-auto" fixed={this.iconSetSchedule} alt="Icon set schedule" objectFit="contain"/>
                    </div>
                    <div className="text-primary">
                      Tell Us How Often
                    </div>
                    <p>
                      Whether you want to receive your product once a week, once a month, or once a year (or anything in between), you get to make the call.
                    </p>
                  </div>
                  <div className="col-12 col-md-3 my-3 text-center about-subscription-details">
                    <div className="icon-image-container d-flex align-items-center mb-5">
                      <Img className="lazyload mx-auto" fixed={this.iconNoContract} alt="Icon no contract" objectFit="contain"/>
                    </div>
                    <div className="text-primary">
                      Cancel Anytime
                    </div>
                    No advanced notice needed. Just let us know when you want to cancel and it will be done immediately.</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="guarantee-section bg-light">
          <div className="container">
            <div className="row text-center d-md-block">
              <div className="col-6 col-md-2 mx-auto">
                <Img fluid={ this.moneyGuarantee } alt="Honest Paws Money Back Guarantee"/>
              </div>
              <div className="col-10 col-md-8 mx-auto text-primary my-4">
                30 Day Money Back Guarantee
              </div>
              <p className="col-10 col-md-8 mx-auto">
                Unhappy with your purchase? Just let us know and we will process your full refund immediately, no questions asked.
              </p>
              <div className="col-12 col-md-4 mx-auto">
                <a href="https://www.honestpaws.com/collections/" target="_top" className="btn btn-primary my-5">Shop Auto-Refill Products!</a>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default aboutSubscriptionPage

export const aboutSubscriptionQuery = graphql`
  query aboutSubscriptionQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    iconNoContract: file(relativePath: { eq: "landing-pages/icon-no-contract.png" }) {
      childImageSharp {
        fixed (width: 187, height: 86) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconSaveMoney: file(relativePath: { eq: "landing-pages/icon-save-money.png" }) {
      childImageSharp {
        fixed (width: 147, height: 134) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconSelectProduct: file(relativePath: { eq: "landing-pages/icon-select-product.png" }) {
      childImageSharp {
        fixed (width: 189, height: 118) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iconSetSchedule: file(relativePath: { eq: "landing-pages/icon-set-schedule.png" }) {
      childImageSharp {
        fixed (width: 133, height: 137) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    moneyGuarantee: file(relativePath: { eq: "money_guarantee.png" }) {
      childImageSharp {
        fluid (maxWidth: 400, maxHeight: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`